import React from "react"

const DonateButton = (props) => {
  let styles = `${props.styles ? props.styles: ''} bg-donate-red hover:bg-donate-red-hover rounded p-4`;
  return (
    <a href="/donate/" className={styles}>
      <span className="leading-loose text-white">
        {props.children}
      </span>
    </a>
  );
};

export default DonateButton;
