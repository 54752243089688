import { Link } from "gatsby"
import React from "react";

const NavItem = (props) => {
  return (
    <Link to={props.path} className="text-black text-sm font-bold font-sans hover:text-tory-blue pr-4">
      {props.label}
    </Link>
  );
};

export default NavItem;
