import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState}  from "react"
import NavItem from "./NavItem";
import DonateButton from "./DonateButton";

const Header = (props) => {
  const [active, setActive] = useState(false);
  const size = active ? 'h-48' : 'h-24';
  return (
    <nav className={`bg-white fixed shadow-lg ${size} w-full border-b-2 border-gray-600 z-50 flex items-center justify-between flex-wrap p-6`}>
      <div className="flex items-center flex-shrink-0 mr-6">
        <span className="font-semibold text-xl tracking-tight text-tory-blue">
          <Link
            to="/"
            className="text-tory-blue"
            title={props.description}
          >
            <h1 className="inline text-black absolute top-0 pt-8 md:static md:pt-0">Don't <span className="text-tory-blue">Vote Conservative</span></h1>
          </Link>
        </span>
      </div>
      <div className="block lg:hidden">
        <button className="flex items-center px-3 py-2 border rounded" onClick={() => setActive(!active)}>
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>
      </div>
      <div className="w-full hidden flex-grow md:block lg:flex lg:items-center lg:w-auto">
        <div className="text-sm lg:flex-grow">
          <NavItem path="/meet-boris/" label="Meet Boris" />
          <NavItem path="/our-plans/" label="Manifesto" />
          <DonateButton>
            Donate
          </DonateButton>
        </div>
      </div>
      <div className={'w-full items-center ' + (active ? 'block': 'hidden')}>
        <Link to="/meet-boris/" className="text-black text-sm font-bold font-sans hover:text-tory-blue pr-4 pt-4 block w-full">
          Meet Boris
        </Link>
        <Link to="/our-plans/" className="text-black text-sm font-bold font-sans hover:text-tory-blue pr-4 pt-4 block w-full">
          Our Plans
        </Link>
        <Link to="/donate/" className="text-black text-sm font-bold font-sans hover:text-tory-blue pr-4 pt-4 block w-full">
          Donate 
        </Link>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
